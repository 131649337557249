<template>
  <div class="menu-wrap" :class="{ '-open': showMenu }">
    <a
      href="javascript:void(0)"
      class="toggle-menu"
      title="Toggle menu"
      @click="showMenu = !showMenu"
    >
      <span />
      <span />
      <span />
    </a>

    <nav class="menu">
      <aside
        id="slide-out"
        class="side-nav fixed p-0"
        v-if="data"
      >
        <router-link
          to="/"
          class="d-flex justify-content-center"
        >
          <h2 class="text-light mt-4 p-0">CCXP Worlds 2020</h2>
        </router-link>

        <div class="mt-3 mb-2 text-center">
          <a
            href="javascript:void(0)"
            class="border mx-2 button-language bt-lang"
            v-for="item in languages"
            :key="item"
            :class="{ '-active': $i18n.locale === item }"
            @click="changeLanguage(item)"
          >
            {{ item }}
          </a>
        </div>

        <template>
          <div v-for="key in Object.keys(data)" :key="data[key].nameEN" class="pt-3">
            <h3 class="pl-2">
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                class="bi bi-chevron-double-right"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5
                  0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"
                />

                <path
                  fill-rule="evenodd"
                  d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5
                  0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"
                />
              </svg>
              {{ data[key][menuLanguageKey] }}
            </h3>

            <div>
              <ul class="m-0 p-0">
                <li
                  v-for="item in data[key].items"
                  :key="`menu-${item[menuLanguageKey]}-${item.url}`"
                >
                  <router-link
                    :to="{ name: item.url }"
                    class="btn right"
                  >
                    {{ item[menuLanguageKey] }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </aside>

      <aside id="slide-out" class="side-nav fixed p0 mt-5 text-center">
        <a
          href="javascript:void(0)"
          class="btn btn-sm btn-danger text-uppercase text-light w-50"
          @click="logout"
        >
          {{ $t('buttons.logout') }}

          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="bi bi-door-open"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M1 15.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0
              1h-13a.5.5 0 0 1-.5-.5zM11.5 2H11V1h.5A1.5 1.5 0 0
              1 13 2.5V15h-1V2.5a.5.5 0 0 0-.5-.5z"
            />

            <path
              fill-rule="evenodd"
              d="M10.828.122A.5.5 0 0 1 11 .5V15h-1V1.077l-6 .857V15H3V1.5a.5.5
              0 0 1 .43-.495l7-1a.5.5 0 0 1 .398.117z"
            />
            <path d="M8 9c0 .552.224 1 .5 1s.5-.448.5-1-.224-1-.5-1-.5.448-.5 1z"/>
          </svg>
        </a>
      </aside>
    </nav>
  </div>
</template>

<script>
import Auth from '../../services/Auth';

export default {
  data() {
    const data = {
      showMenu: false,
      languages: ['pt', 'en'],
    };

    return data;
  },

  watch: {
    // eslint-disable-next-line object-shorthand
    '$route.path'() {
      this.showMenu = false;
    },
  },

  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang;
    },

    logout() {
      Auth.logout(false);
      this.$router.push('/login');
    },
  },

  computed: {
    data() {
      return this.$attrs.items;
    },

    menuLanguageKey() {
      const obj = {
        en: 'nameEN',
        pt: 'namePT',
        default: 'nameEN',
      };

      return obj[this.$i18n.locale] || obj.default;
    },
  },
};
</script>

<style lang="sass" scoped>
  .menu-wrap
    .toggle-menu
      display: none

    .menu
      width: 260px
      position: fixed
      background-color: #000
      overflow-y: auto
      max-height: 100vh
      min-height: 100vh
      border-right: 2px solid #ffcc00
      transition: all 250ms ease

      /* width */
      &::-webkit-scrollbar
        width: 10px

      /* Track */
      &::-webkit-scrollbar-track
        background: #f1f1f1

      /* Handle */
      &::-webkit-scrollbar-thumb
        background: #888

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover
        background: #555

      h2
        width: 100%
        text-indent: -5000px
        background:
          image: url(/img/logo.png)
          repeat: no-repeat
          position: center
          size: contain

      aside
        .button-language
          font-size: 12px
          text-transform: uppercase
          color: #fff
          width: 27px
          height: 27px
          line-height: 25px
          display: inline-block
          transition: all 150ms ease

          &:hover
            text-decoration: none

          &:hover,
          &.-active
            background: #fff
            color: #000

        h3
          background-color: #ffcc00
          font-size: 15px
          text-transform: uppercase
          width: 100%
          display: block
          padding: 5px 0 6px
          border-radius: 0
          text-align: left
          font-weight: bolder
          color: #000
          margin: 0

        ul
          li
            display: block
            border-bottom: 1px solid #313437
            text-indent: 10px
            background-color: rgba(255, 255, 255, 0.2)

            &:last-child
              border: none

            a
              font-size: 14px
              color: #fff
              font-weight: 300
              display: block
              text-align: left
              border-radius: 0
              transition: all 300ms ease
              padding: 3px 3px

              &.active,
              &:hover
                background-color: rgba(255, 255, 255, 0.3)
                color: #fff
                transition: all 300ms ease

        ul
          li
            border-color: rgba(#fff, 0.2)

  @media (max-width: 1024px)
    .menu-wrap
      width: 100vw
      height: 100vh
      top: 0
      left: 0
      z-index: 1000
      position: fixed
      pointer-events: none

      &.-open
        pointer-events: all

        &:after
          opacity: 1

        .toggle-menu
          left: 260px

        .menu
          transform: translate(0)

      &:after
        content: ''
        position: absolute
        left: 0
        top: 0
        width: 100vw
        height: 100vh
        background: rgba(#000, 0.5)
        transition: all 250ms ease
        z-index: -1
        opacity: 0

      .toggle-menu
        display: flex
        flex-direction: column
        align-items: center
        justify-content: space-between
        width: 37px
        height: 37px
        padding: 8px 6px
        position: absolute
        transition: all 250ms ease
        top: 0
        left: 0
        background: #303030
        pointer-events: all

        span
          height: 3px
          width: 100%
          background: #fff
          border-radius: 10px

      .menu
        border-right: 2px solid #000
        transform: translateX(-100%)
</style>
