<template>
  <div class="dash-view">
    <div class="page d-flex" :class="{ loaded }">
      <SidebarMenu :items="menuItems" />

      <div class="content d-flex flex-column bg-light">
        <transition name="fade" mode="out-in">
          <router-view class="main-view p-3" />
        </transition>

        <footer
          class="d-flex align-items-center justify-content-center text-dark text-center"
        >
          <small>{{ $t("footer.message") }}</small>
        </footer>
      </div>
    </div>

    <b-modal
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :cancel-disabled="true"
      centered
      size="md"
      v-b-modal.blocked
      v-model="showBlocked"
    >
      <div class="d-block" v-html="$t('messages.tableBlocked')"></div>
      <div class="d-block mt-2 text-right"></div>

      <template v-slot:modal-header="{}">
        <h5 class="mb-0">{{ $t("general.blockedTitle") }}</h5>
      </template>

      <template v-slot:modal-footer="{ }">
        <div></div>
      </template>
    </b-modal>

    <b-modal
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :cancel-disabled="true"
      centered
      size="xl"
      v-b-modal.agreement
      v-model="showAgreement"
      @ok="acceptAgreement"
    >
      <div class="d-block" v-html="$t('messages.'+artistType+'Agreement')"></div>
      <div class="d-block mt-2 text-right"></div>

      <template v-slot:modal-header="{}">
        <h5 class="mb-0">{{ $t("general.agreementTitle") }}</h5>
      </template>

      <template v-slot:modal-footer="{ }">
        <b-form-checkbox v-model="agreementAccepted" name="check-button" switch>
          <strong>{{ $t("inputs.acceptAgreement") }}</strong>
        </b-form-checkbox>
        <b-button
          class="pr-4 pl-4 ml-4"
          :disabled="!agreementAccepted"
          size="md"
          variant="success"
          @click="acceptAgreement()"
        >
          {{ $t("buttons.sign") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import * as Sentry from '@sentry/browser';
import MenuService from '../../services/Menu';
import AuthService from '../../services/Auth';
import SidebarMenu from '../../components/organisms/SidebarMenu.vue';
import { STORAGE } from '../../assets/js/consts';
import { adaptPermissions } from '../../assets/js/helpers';
import UsersPermissions from '../../services/UsersPermissions';
import Toast from '../../assets/js/toast';

export default {
  components: {
    SidebarMenu,
  },

  data() {
    const data = {
      loaded: false,
      menuItems: null,
      showAgreement: false,
      showBlocked: false,
      agreementAccepted: false,
      artistType: '',
    };

    return data;
  },

  beforeMount() {
    this.loadMenu();
    this.configureSentry();
  },

  methods: {
    configureSentry() {
      try {
        const user = JSON.parse(localStorage.getItem(STORAGE.USER) || '{}');

        if (user && user.id) {
          Sentry.configureScope((scope) => {
            scope.setUser({
              email: user.email,
              username: user.username,
              id: `${user.id}`,
            });

            scope.setExtra('language', user.language);
            scope.setExtra('type', user.type);
            scope.setExtra('profileType', user.profileType || '');
          });
        }
      } catch (e) {
        //
      }
    },

    async acceptAgreement() {
      try {
        await UsersPermissions.acceptAgreement();
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      } catch (e) {
        Toast.error(this, e);
      }
    },

    async loadMenu() {
      try {
        const xhr = await MenuService.get();

        const menu = adaptPermissions(xhr, true);

        const usr = AuthService.getUser();
        if (
          usr.type === 'artist'
          && xhr
          && xhr.length > 0
        ) {
          if (xhr[0].blocked) {
            this.showBlocked = true;
          } else if (!xhr[0].agreementSigned) {
            this.artistType = usr.profileType.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
            this.showAgreement = true;
          }
        }

        this.menuItems = menu;
        this.loaded = true;
      } catch (e) {
        Sentry.captureException(e);
        AuthService.logout();
        this.$router.push({ name: 'login' });
      }
    },
  },
};
</script>

<style lang="sass" scoped>
  .main-view
    flex: 1 0 auto
    min-height: calc(100vh - 80px)

  header
    height: 70px
    border-bottom: 2px solid #666

  .page
    width: 100%
    min-height: 100vh
    opacity: 0
    transition: all 150ms ease
    min-height: 100vh

    &.loaded
      opacity: 1

  .content
    padding-left: 260px
    width: 100%

  footer
    border-top: 1px solid #6e6e6e
    height: 80px

  @media (max-width: 1024px)
    .content
      padding-left: 0
      padding-top: 30px
</style>
