import Vue from 'vue';

export default {
  get: async () => Vue.http.get('permission')
    .then((res) => res.json()),

  acceptAgreement: async () => (
    Vue.http.post('permission/agreement').then((res) => res.json())
  ),

};
