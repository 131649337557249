export const adaptPermissions = (arr, isSidebarView = false) => {
  const menu = {};

  arr.filter((item) => item.namePT.indexOf('*') === -1).map((item) => {
    if (item.hide && isSidebarView) {
      return item;
    }

    const menuItem = {
      namePT: item.namePT,
      nameEN: item.nameEN,
      url: item.url,
      id: item.id,
    };

    if (!menu[item.sectionEN]) {
      menu[item.sectionEN] = {
        namePT: item.sectionPT,
        nameEN: item.sectionEN,
        items: [menuItem],
      };

      return item;
    }

    menu[item.sectionEN].items.push(menuItem);
    return item;
  });

  return menu;
};

export const todo = true;
